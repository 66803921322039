$(document).ready(function () {

    new AddressCheck().initialize();

});

function AddressCheck() {

    var formReference = ".introFormBanner form";
    var addressValidationClass = "invalid-address";
    var generalErrorMessageClass = "general-validation-message";
    var animationDuration = 100;
    var placeholderFormItems = new PlaceholderFormItems();
    const productsChoiceHolderClass = 'products-choice-wrapper';
    const productsChoiceErrorClass = 'products-choice-error';
    var address = {
        postalCode: '',
        houseNumber: '',
        addition: '',
        isValidAddress: true
    };

    const pageTypeField = ($(formReference).serializeArray().find(function(formField) {
        return formField.name === 'personal_offer_address_check_form[pageType]'
    }) || {});

    let productsChoiceSet = false

    this.initialize = function() {

        formSubmit();
        formManipulation($(formReference));
        attachProductsChoiceBehavior();

    };

    var formSubmit = function() {

        var form = $(formReference);
        var submitInput = form.find('input[type="submit"]');

        submitInput.unbind();

        form.submit(function(event) {

            event.preventDefault();

            toggleProductsChoiceErrorMessage(false);

            formValidation(form, event);

            if (formIsInvalid(form)) {
                return;
            }

            checkAddress(form, submitInput);

        });
    };

    var formManipulation = function(form) {
        // Remove spaces on all postcode input (this will remove manual input, pasting, and autofill)
        
        var postalCodeInput = form.find('.form-item.postal-code input:enabled');

        $(postalCodeInput).on('input', function (e) {
            const newVal = e.target.value.replace(/\s+/g, '')

            if (newVal !== e.target.value) {
                e.target.value = newVal
            }
        });
    }

    var formValidation = function(form, event) {
        var postalCodeInput = form.find('.form-item.postal-code input:enabled');
        var houseNumberInput = form.find('.form-item.house-number input:enabled');
        var additionSelect = form.find('.form-item.select select:enabled');
        var validationObject = new LiveValidation(postalCodeInput);

        validationObject.postalCode();
        validationObject.required();

        validationObject = new LiveValidation(houseNumberInput);
        validationObject.minimum(1);
        validationObject.maximum(99999);
        validationObject.numbers();
        validationObject.required();

        validationObject = new LiveValidation(additionSelect);
        validationObject.reset();

        if (houseNumberInput.val() === address.houseNumber && postalCodeInput.val() === address.postalCode) {

            validationObject.required();

        }

        validationObject = new LiveValidation(form);
        validationObject.validateForm(event);
        
        if (pageTypeField.value === 'personalOffer') {
            productsChoiceSet = Boolean(form.serializeArray().filter(function(formField) {
                return (formField.name.includes('isEnergyClient') || formField.name.includes('isMobileClient'))
                    && Boolean(formField.value) 
            }).length)

            toggleProductsChoiceErrorMessage(!productsChoiceSet)
        }
    };

    var formIsInvalid = function(form) {

        var invalidFormItem = form.find('.form-item.invalid');
        var addressInvalidFormItem = form.find('.form-item.' + addressValidationClass);
        
        return invalidFormItem.length || addressInvalidFormItem.length || !productsChoiceSet;
    };

    var checkAddress = function(form, submitInput) {
        var formData = form.serialize();
        var formBlock = '#addressCheckBlock';
        var timeoutDuration = 500;

        showSpinner(form, submitInput);

        setTimeout(function() {

            $.ajax({
                'method': 'POST',
                'url': Routing.generate('xhr_home_checkAddress'),
                'data': formData,
                'dataType': 'json'
            }).done(function (result) {
                if (result.isFormValid) {
                    setTimeout(function (){hideSpinner(form, submitInput)}, 1000);

                    window.location = Routing.generate('allInOne_result');
                    return;
                }

                $(formBlock).replaceWith(
                    $(result.formHtml).find(formBlock)
                );

                reAttachFormBehaviour();

            }).fail(function() {

                ajaxCallFailed(form);

            });

        }, timeoutDuration);

    };

    var reAttachFormBehaviour = function() {
        var form = $(formReference);
        var postalCodeInput = form.find('.form-item.postal-code input');
        var houseNumberInput = form.find('.form-item.house-number input');
        var additionInput = form.find('.form-item.house-addition input');
        var additionFormItem = form.find('.form-item.house-addition select');
        var selectFormItem = form.find('.form-item.select');
        var radioInput = form.find('input[type=radio]');
        var checkedRadioInput = form.find('input[type=radio]:checked');
        var checkboxAndRadioButtonFunctionality = new CheckboxAndRadioButtonFunctionality();

        setAddress(houseNumberInput, postalCodeInput, additionInput);
        addSelectOverlay(selectFormItem);

        houseNumberInput.on('input', function() {
            var validationObject = new LiveValidation(this);
            validationObject.reset();
            toggleAddressInputValidation(form, houseNumberInput, postalCodeInput, additionInput);
            houseNumberValidation($(this));
            silentRequiredValidation($(this));
        });

        postalCodeInput.on('input', function() {
            var validationObject = new LiveValidation(this);
            validationObject.reset();
            toggleAddressInputValidation(form, houseNumberInput, postalCodeInput, additionInput);
            postalCodeValidation($(this));
            silentRequiredValidation($(this));
        });

        if(additionInput.length !== 0){
            additionInput.on('input', function() {
                var validationObject = new LiveValidation(this);
                validationObject.reset();
                toggleAddressInputValidation(form, houseNumberInput, postalCodeInput, additionInput);
            });
            placeholderFormItems.toggleActive(additionInput);
        }

        selectFormItem.find('select').on('change', function() {
            var validationObject = new LiveValidation(this);
            validationObject.reset();
            toggleAddressInputValidation(form, houseNumberInput, postalCodeInput, additionInput);
        });

        placeholderFormItems.toggleActive(postalCodeInput);
        placeholderFormItems.toggleActive(houseNumberInput);
        $(additionFormItem).addClass('active');

        checkboxAndRadioButtonFunctionality.toggleCheckedClass(checkedRadioInput);

        radioInput.on('change', function() {
            checkboxAndRadioButtonFunctionality.toggleCheckedClass($(this));
        });

        var selectInputTrigger = selectFormItem.find('.overlay-toggle');

        selectInputTrigger.trigger('click');

        var tooltips = form.find('.tooltip');

        new Tooltips().initialize(tooltips);

        [
            'input[name="address_check_form[isMobileClient]"]',
            'input[name="address_check_form[isEnergyClient]"]',
            'input[name="address_check_form[isBudgetClient]"]', 
            'input[name="personal_offer_address_check_form[isEnergyClient]"]', 
            'input[name="personal_offer_address_check_form[isMobileClient]"]'
        ].forEach(function(selector) {
            if (Boolean(form.find($(selector)).attr('checked'))) {
                form.find(selector).parent().addClass('checked')
                form.find(selector).prop('checked', true)
            }
        })
        
        formSubmit();
        attachProductsChoiceBehavior();
    };

    var setAddress = function(houseNumberInput, postalCodeInput, additionInput) {
        var isInvalidAddress = houseNumberInput.closest('.form-item').hasClass(addressValidationClass) && postalCodeInput.closest('.form-item').hasClass(addressValidationClass) && additionInput.closest('.form-item').hasClass(addressValidationClass);
        address.addition = additionInput !== null? additionInput.val(): '';

        address.houseNumber = houseNumberInput.val();
        address.postalCode = postalCodeInput.val();
        address.isValidAddress = !isInvalidAddress;

    };

    var toggleAddressInputValidation = function(form, houseNumberInput, postalCodeInput, additionInput) {
        var houseNumberFormItem = houseNumberInput.closest('.form-item');
        var postalCodeFormItem = postalCodeInput.closest('.form-item');
        var validClass = "valid";
        var houseNumberInputValue = houseNumberInput.val();
        var postalCodeInputValue = postalCodeInput.val();
        var addressValidationMessage = form.find('.general-validation-message.error');
        var invalidClass = "invalid";
        var additionInputValue = additionInput.length !== 0? additionInput.val(): '';
        var additionFormItem = additionInput.closest('.form-item');

        if (houseNumberInputValue !== address.houseNumber || postalCodeInputValue !== address.postalCode || additionInputValue !== address.addition) {
            houseNumberFormItem.removeClass(addressValidationClass);
            postalCodeFormItem.removeClass(addressValidationClass);
            additionFormItem.removeClass(addressValidationClass);
            addressValidationMessage.slideUp(animationDuration);

        } else {

            houseNumberFormItem.addClass(addressValidationClass);
            postalCodeFormItem.addClass(addressValidationClass);
            additionFormItem.addClass(addressValidationClass);
            addressValidationMessage.slideDown(animationDuration);

        }

        if (houseNumberInputValue != "" && !houseNumberFormItem.hasClass(invalidClass)) {

            houseNumberFormItem.addClass(validClass);

        }

        if (postalCodeInputValue != "" && !postalCodeFormItem.hasClass(invalidClass)) {

            postalCodeFormItem.addClass(validClass);

        }

        if (additionInputValue != "" && !additionFormItem.hasClass(invalidClass)) {

            additionFormItem.addClass(validClass);

        }

    };

    var showSpinner = function(form, submitInput) {

        var spinner = new Spinner(form, form);

        spinner.add();
        spinner.show();
        submitInput.hide();

    };

    var hideSpinner = function(form, submitInput) {

        var spinner = new Spinner(form, form);

        spinner.hide();

    };

    var ajaxCallFailed = function(form) {

        removeErrorMessage(form);
        showErrorMessage(form);
        hideSpinner(form);

    };

    var showErrorMessage = function(form) {

        var errorMessage = "Oeps, er ging iets mis. Probeer het later nog eens.";

        form.prepend('<div class="' + generalErrorMessageClass +' error">' + errorMessage + '</div>')

    };

    var removeErrorMessage = function(form) {

        form.find('.' + generalErrorMessageClass).remove();

    }

    const toggleProductsChoiceErrorMessage = function(showError) {
        if (showError) {
            $('.' + productsChoiceHolderClass)
            .append(
                '<div class="' + generalErrorMessageClass +' error '+ productsChoiceErrorClass +'">Kies je huidige product(en)</div>'
            );
        } else {
            $('.' + productsChoiceErrorClass).remove()
        }
    }

    const attachProductsChoiceBehavior = function() {
        if (pageTypeField.value === 'personalOffer') {
            const isMobileClientCheckbox = $(formReference).find('input[name="personal_offer_address_check_form[isMobileClient]"]');
            const isEnergyClientCheckbox = $(formReference).find('input[name="personal_offer_address_check_form[isEnergyClient]"]');
            const clickHandlerPersonalOffer = function(e) {
                if ($(e.target).prop('checked')) {
                    $(e.target).parent().addClass('checked')
                } else {
                    $(e.target).parent().removeClass('checked')
                }
            }
            isEnergyClientCheckbox.on('click', clickHandlerPersonalOffer);
            isMobileClientCheckbox.on('click', clickHandlerPersonalOffer);
           return     
        }

        productsChoiceSet = true;

        const isMobileClientCheckbox = $(formReference).find('input[name="address_check_form[isMobileClient]"]');
        const isEnergyClientCheckbox = $(formReference).find('input[name="address_check_form[isEnergyClient]"]');
        const isClientCheckbox = $(formReference).find('input[name="address_check_form[isBudgetClient]"]');

        const clickHandler = function(e) {
            if($(isMobileClientCheckbox).prop('checked') || $(isEnergyClientCheckbox).prop('checked')) {
                if ($(isClientCheckbox).prop('checked')) {
                    $(isClientCheckbox).trigger('click');
                    $(isClientCheckbox).parent().removeClass('checked')
                }
            } else if (!$(isMobileClientCheckbox).prop('checked') && !$(isEnergyClientCheckbox).prop('checked')) {
                if (!$(isClientCheckbox).prop('checked')) {
                    $(isClientCheckbox).trigger('click');
                    $(isClientCheckbox).parent().addClass('checked')
                }
            }

            if ($(e.target).prop('checked')) {
                $(e.target).parent().addClass('checked')
            } else {
                $(e.target).parent().removeClass('checked')
            }
        }

        const clientClickHandler = function(e) {
            if (e.originalEvent.isTrusted) {                
                if(!$(isClientCheckbox).prop('checked')) {
                    e?.originalEvent?.preventDefault();
                    return
                }
                $(isMobileClientCheckbox).prop('checked') && $(isMobileClientCheckbox).trigger('click');
                $(isEnergyClientCheckbox).prop('checked') && $(isEnergyClientCheckbox).trigger('click');
            }
            $(isClientCheckbox).parent().addClass('checked')
        }

        
        isClientCheckbox.on('click', clientClickHandler)
        isEnergyClientCheckbox.on('click', clickHandler);
        isMobileClientCheckbox.on('click', clickHandler);
    }
}
